<template>
  <div class="order-page container-fluid" v-if="orderData">

    <div class="h-top">
      <router-link :to="{path: this.$route.query?.back == 'list' ? '/order' : '/place/map'}" class="button-back-empty">
        <span class="fa fa-arrow-left"></span>
      </router-link>
    </div>

    <div class="order-view">
      <div class="card mb-4 rounded-3">
        <div class="card-body">
           <span class="price-block">
            <span class="fa fa-circle-o"></span>
            {{ this.getTotalAmount(orderData.price, count, orderData.payAmount) }}{{ this.getCurrencySymbol() }}
          </span>
          <span class="status-block" :class="'status-block-' + orderData.status">
            <span v-if="orderData.status == orderStatusEnum.ready">
              payment required
            </span>
            <span v-else-if="orderData.status == orderStatusEnum.open">
              training started <br />
            </span>
            <span v-else-if="orderData.status == orderStatusEnum.stop">
              payment wating <br />
            </span>
            <span v-else-if="orderData.status == orderStatusEnum.close">
              training is completed
            </span>
            <span v-else-if="orderData.status == orderStatusEnum.reject">
              training canceled
            </span>
          </span>
          <div class="order">Order #{{ orderData.id }}</div>
          <div class="title">{{ placeData.title }}</div>
          <div class="date">
            <div v-if="orderData.dateStart">
              Start time:
              {{ this.getDateTime(orderData.dateStart) }}
            </div>
            <div v-else>
              Date:
              {{ this.getDateTime(orderData.dateCreate) }}
            </div>
            <div v-if="orderData.dateFinish">
              End time:
              {{ this.getDateTime(orderData.dateFinish) }}
            </div>
          </div>
          <hr class="mb-0" />
          <div class="amount">
            <span class="caption">Total amount:</span>
            {{ this.getPrice(orderData.price) }}{{ this.getCurrencySymbol() }} x {{ count }}m =
            <strong>{{ this.getTotalAmount(orderData.price, count, orderData.payAmount) }}{{ this.getCurrencySymbol() }}</strong>
            <span class="need" v-if="orderData.payAmount > 0">
               <br />
              <span class="caption">Payed amount: </span>
              <strong>{{ this.getPrice(orderData.payAmount) }}{{ this.getCurrencySymbol() }}</strong>
            </span>
            <span class="need" v-if="this.getNeedPayAmount(orderData.price, count, orderData.payAmount) > 0">
              <br />
              <span class="caption">Need pay amount: </span>
              <strong>{{ this.getNeedPayAmount(orderData.price, count, orderData.payAmount) }}{{ this.getCurrencySymbol() }}</strong>
            </span>
          </div>
          <div class="duration" v-if="duration">
            <span class="fa fa-clock-o"></span>
            {{ duration }}
          </div>
          <div class="cheque" v-if="chequeData">
            <a :href="chequeData.url" target="_blank">receipt link</a>
          </div>
        </div>
      </div>
    </div>

    <div class="buttons" v-if="orderData && orderData.status == orderStatusEnum.open">
      <div class="alert alert-info">
        <router-link :to="{path: '/place/code/view'}" class="text-info text-bold">Scan QR-code</router-link>
        to complete training and make a payment
      </div>
    </div>

  </div>
</template>

<script>
import OrderMixin from '@/modules/order/mixins/OrderMixin';
import {OrderStatusEnum} from '@/modules/order/enums/OrderEnum';

export default {

  mixins: [OrderMixin],

  data: () => ({
    orderData: null,
    placeData: null,
    chequeData: null,
    duration: null,

    amount: 0,
    count: 0,

    orderStatusEnum: OrderStatusEnum,
    timerObject: null
  }),

  async mounted() {

    if (!await this.checkUser())
      return;

    await this.doOrderView();

    this.initOrderTimer();
  },

  methods: {

    async doOrderView() {

      let apiHolder = await this.sendApiGet(['api/order/view/' + this.$route.params?.id]);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.orderData)
        this.orderData = apiHolder.data.orderData;
      if (apiHolder.data?.placeData)
        this.placeData = apiHolder.data.placeData;
      if (apiHolder.data?.chequeData)
        this.chequeData = apiHolder.data.chequeData;
    },

    async doOrderClose() {

      let apiHolder = await this.sendApiPost(['api/order/status/close/' + this.orderData?.id]);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.orderData)
        this.orderData = apiHolder.data.orderData;
    },

    updateData() {

      this.count = this.getCalcOrderCount(this.orderData.dateStart, this.orderData.dateFinish,
          this.orderData.count, this.orderData.maxCount);

     this.duration = this.getOrderDuration(this.orderData.dateStart, this.orderData.dateFinish,
         this.orderData.maxCount);
    },

    initOrderTimer() {

      if (!this.orderData || !this.orderData.dateStart)
        return;

      this.updateData();

      if (this.orderData.status != OrderStatusEnum.open)
        return;

      this.timerObject = setInterval(() => {

        this.updateData();

      }, 1000);
    }
  },

  beforeUnmount() {

    if (this.timerObject)
      clearInterval(this.timerObject);
  }

}
</script>

<style scoped>

.order-view {
  padding-top: 30px;
}

.order-view .card {
  background: #343434;
  color: #FFF;
  margin-bottom: 20px !important;
}

.order-view .card .price-block {
  background: #FFFA83;
  position: absolute;
  top: -15px;
  left: 15px;
  line-height: 1.5em;
  font-size: 14px;
  color: #000;
  font-weight: 700;
  border-radius: 38px;
  padding: 4px 10px 4px 5px;
  display: flex;
}

.order-view .card .price-block .fa {
  font-size: 20px;
  padding-right: 8px;
}

.order-view .card .status-block {
  background: #2CDAE5;
  position: absolute;
  top: -15px;
  right: 15px;
  line-height: 1.5em;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  border-radius: 38px;
  padding: 4px 15px 4px 15px;
  display: flex;
}

.order-view .card .status-block-ready,
.order-view .card .status-block-open {
  background: #FF007A;
}

.order-view .card-body {
  padding-top: 5px;
  padding-bottom: 10px;
  position: relative;
}

.order-view .card .title {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 20px;
}

.order-view .card .order {
  color: #EBEAEC;
  font-size: 13px;
  float: right !important;
  font-weight: 500;
  padding-top: 20px;
}

.order-view .card .work-time {
  color: #FFFA83;
  font-weight: 500;
}

.order-view .card .amount {
  padding-top: 10px;
  color: #FFFA83;
  font-weight: 500;
}

.order-view .card .amount .caption {
  color: #fff;
}

.order-view .card .duration {
  padding-top: 10px;
  font-weight: 700;
}

.order-view .card .cheque {
  padding-top: 10px;
}

.order-view .card .cheque a {
  color: #FFF !important;
}

.buttons {
  padding-bottom: 20px;
}

</style>